import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrStorage from '@/modules/common/services/storage.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'
import GrtSecurityLayout from '../../../layouts/security/security.layout.vue'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrChangePasswordView'
})
export default class ChangePasswordView extends Vue {
  @Prop()
  readonly changePasswordForm!: InstanceType<typeof ValidationObserver>;

  data () {
    return {
      submitting: false,
      changePassword: {
        password: null,
        password_confirmation: null,
        token: null
      }
    }
  }

  created () {
    this.$emit('update:layout', GrtSecurityLayout)
  }

  mounted () {
    const token = this.$route.params.token
    if (token) {
      this.$data.changePassword.token = token
    } else {
      this.$router.push({ name: 'security.login' })
    }
  }

  async submit () {
    const form = this.$refs.passwordChangeForm as HTMLFormElement
    if (form.validate()) {
      try {
        this.$data.submitting = true
        this.$data.changePassword.admin_base_url = window.location.origin
        await this.$store.dispatch('security/changePassword', this.$data.changePassword)
        this.$router.push({ name: 'security.login' })
        this.$data.changePassword = {
          password: null,
          password_confirmation: null,
          token: null
        }
        Container.get(Notification).success('Password changed successfully')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        const observer = (this.$refs.changePasswordForm as any)
        observer.reset()
        form.reset()
        this.$data.submitting = false
      }
    }
  }
}
